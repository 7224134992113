<template>
  <div id="paymentBusinessLinesId" style="width: 100%; height: 300px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'PaymentBusinessLines',
  props: {
    businessLinesList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    businessLinesList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    init(arr = []) {
      this.$nextTick(() => {
        var chartDom = document.getElementById('paymentBusinessLinesId')
        var myChart = echarts.init(chartDom)
        var option
        option = {
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              // params.data 是当前数据项
              const categoryCount = Number(params.data.categoryCount).toLocaleString()
              const value = Number(params.data.categoryPaymentAmount).toLocaleString()
              return `${params.name}：<br/>数量：${categoryCount}个<br/> 回款总金额：${value}元<br/> 金额占比：${params.data.paymentPercentage}%`
            },
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['35%', '70%'],
              labelLine: {
                length: 30,
              },
              label: {
                formatter: function (params) {
                  const categoryCount = Number(params.data.categoryCount).toLocaleString()
                  const value = Number(params.data.categoryPaymentAmount).toLocaleString()
                  return `{a|${params.name}：}{b|${categoryCount}}个{per|${params.data.paymentPercentage}%} `
                },
                backgroundColor: '#fff',
                borderColor: '#8C8D8E',
                rich: {
                  hr: {
                    borderColor: '#fff',
                    width: '100%',
                    height: 0,
                  },
                  a: {
                    color: '#4C5058',
                    fontSize: 15,
                    fontWeight: 'bold',
                    align: 'left',
                  },
                  b: {
                    color: '#4C5058',
                    fontSize: 15,
                    fontWeight: 'bold',
                  },
                  per: {
                    color: '#fff',
                    backgroundColor: '#73c0de',
                    padding: [3, 4],
                    fontSize: 15,
                    borderRadius: 4,
                  },
                },
              },
              data: arr.map((item, index) => ({
                ...item,
                value: item.categoryPaymentAmount,
                itemStyle: {
                  color: [
                    '#f8d347',
                    '#EE9201',
                    '#29AAE3',
                    '#B74AE5',
                    '#0AAF9F',
                    '#E89689',
                    '#fdcd45',
                    '#8A2BE2',
                    '#FF1493',
                    '#7FFFAA',
                    '#0000FF',
                    '#29D289',
                    '#592D89',
                  ][index % 13], // 自定义颜色
                },
              })),
            },
          ],
        }

        option && myChart.setOption(option)
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
